var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"data-cy":"211","fluid":""}},[_c('validation-observer',{attrs:{"ata-cy":"212"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"213"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.loginUsingCredentials(_vm.credentials); })}}},[_c('v-row',{staticClass:"align-self-start justify-center",attrs:{"data-cy":"214"}},[_c('v-col',{staticClass:"col-auto",attrs:{"data-cy":"215"}},[(!_vm.isMobileView)?_c('v-card',{ref:"login_card",staticClass:"ma-md-12 justify-center",attrs:{"data-cy":"216","width":"686","height":"601"}},[_c('v-row',{attrs:{"data-cy":"217"}},[_c('v-col',{staticClass:"col-md-8 offset-md-2",attrs:{"data-cy":"218"}},[_c('v-card-title',{ref:"login_header",staticClass:"mt-8 mb-6 text-center justify-center",attrs:{"data-cy":"226"}},[_c('span',{staticClass:"headline",attrs:{"data-cy":"227"}},[_vm._v(_vm._s(_vm.$t("15")))])]),_c('div',{ref:"login_report_number_label",staticClass:"d-block",attrs:{"data-cy":"228"}},[_c('span',{staticClass:"text-md-subtitle-1 text-sm-h6 text-xs-h6",attrs:{"data-cy":"229"}},[_vm._v(_vm._s(_vm.$t("16")))])]),_c('validation-provider',{attrs:{"data-cy":"230","rules":"required","name":"16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('themis-pin-input',{ref:"input_report_number",attrs:{"data-cy":"231","length":8,"size":_vm.isMobileView ? 31 : 40,"error-messages":errors,"disabled":!!_vm.$route.query.username,"mode":"lazy","hide-details":""},model:{value:(_vm.credentials.username),callback:function ($$v) {_vm.$set(_vm.credentials, "username", $$v)},expression:"credentials.username"}})]}}],null,true)}),(!_vm.isMobileView)?_c('v-menu',{ref:"menu_report_number_definition",attrs:{"data-cy":"232","max-width":"400","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-block text-right mb-12",attrs:{"data-cy":"219"}},[_c('span',_vm._g({staticClass:"secondary--text",attrs:{"data-cy":"220"}},on),[_vm._v(_vm._s(_vm.$t("20")))])])]}}],null,true),model:{value:(_vm.showReportNumberHelp),callback:function ($$v) {_vm.showReportNumberHelp=$$v},expression:"showReportNumberHelp"}},[_c('v-card',{staticClass:"pb-6",attrs:{"data-cy":"221","white":""}},[_c('div',{staticClass:"d-block text-right",attrs:{"data-cy":"427"}},[_c('v-btn',{ref:"button_close_help",attrs:{"data-cy":"428","icon":""},on:{"click":function($event){_vm.showReportNumberHelp = false}}},[_c('v-icon',{attrs:{"data-cy":"428"}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"d-block px-md-10 text-center text--secondary",attrs:{"data-cy":"222"}},[_c('span',{attrs:{"data-cy":"223"}},[_vm._v(_vm._s(_vm.$t("21")))]),_c('br',{attrs:{"data-cy":"224"}}),_c('span',{staticClass:"link",attrs:{"data-cy":"225"},domProps:{"innerHTML":_vm._s(_vm.$t('22'))},on:{"click":function($event){return _vm.$router.push('languages')}}})])],1),_c('v-img',{staticClass:"mt-4",attrs:{"data-cy":"430","src":require("@/assets/images/forgot-password.svg"),"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '686',"height":"180","contain":""}})],1)],1):_c('v-bottom-sheet',{ref:"bottom_sheet_report_number_definition",attrs:{"data-cy":"233"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-block text-right mb-12",attrs:{"data-cy":"219"}},[_c('span',_vm._g({staticClass:"secondary--text",attrs:{"data-cy":"220"}},on),[_vm._v(_vm._s(_vm.$t("20")))])])]}}],null,true),model:{value:(_vm.showReportNumberHelp),callback:function ($$v) {_vm.showReportNumberHelp=$$v},expression:"showReportNumberHelp"}},[_c('v-card',{staticClass:"pb-6",attrs:{"data-cy":"221","white":""}},[_c('div',{staticClass:"d-block text-right",attrs:{"data-cy":"427"}},[_c('v-btn',{ref:"button_close_help",attrs:{"data-cy":"428","icon":""},on:{"click":function($event){_vm.showReportNumberHelp = false}}},[_c('v-icon',{attrs:{"data-cy":"428"}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"d-block px-md-10 text-center text--secondary",attrs:{"data-cy":"222"}},[_c('span',{attrs:{"data-cy":"223"}},[_vm._v(_vm._s(_vm.$t("21")))]),_c('br',{attrs:{"data-cy":"224"}}),_c('span',{staticClass:"link",attrs:{"data-cy":"225"},domProps:{"innerHTML":_vm._s(_vm.$t('22'))},on:{"click":function($event){return _vm.$router.push('languages')}}})])],1),_c('v-img',{staticClass:"mt-4",attrs:{"data-cy":"430","src":require("@/assets/images/forgot-password.svg"),"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '686',"height":"180","contain":""}})],1)],1),_c('div',{staticClass:"d-block",attrs:{"data-cy":"234"}},[_vm._v(_vm._s(_vm.$t("17")))]),_c('validation-provider',{attrs:{"data-cy":"235","rules":"required","name":"26"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"input_password",staticClass:"mb-5",attrs:{"data-cy":"236","label":_vm._f("required")(_vm.$t('26')),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"disabled":!_vm.credentials.username,"error-messages":errors,"color":"secondary","outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"data-cy":"237"}},[_vm._v("mdi-key")])]},proxy:true}],null,true),model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)}),_c('v-btn',{ref:"button_login",staticClass:"subtitle-1 font-weight-bold",attrs:{"data-cy":"238","color":"primary","loading":_vm.isLoggingIn,"disabled":!(_vm.credentials.username && _vm.credentials.password),"type":"submit","block":"","x-large":""}},[_vm._v(_vm._s(_vm.$t("19")))])],1)],1)],1):[_c('v-card-title',{ref:"login_header",staticClass:"mt-8 mb-6 text-center justify-center",attrs:{"data-cy":"226"}},[_c('span',{staticClass:"headline",attrs:{"data-cy":"227"}},[_vm._v(_vm._s(_vm.$t("15")))])]),_c('div',{ref:"login_report_number_label",staticClass:"d-block",attrs:{"data-cy":"228"}},[_c('span',{staticClass:"text-md-subtitle-1 text-sm-h6 text-xs-h6",attrs:{"data-cy":"229"}},[_vm._v(_vm._s(_vm.$t("16")))])]),_c('validation-provider',{attrs:{"data-cy":"230","rules":"required","name":"16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('themis-pin-input',{ref:"input_report_number",attrs:{"data-cy":"231","length":8,"size":_vm.isMobileView ? 31 : 40,"error-messages":errors,"disabled":!!_vm.$route.query.username,"mode":"lazy","hide-details":""},model:{value:(_vm.credentials.username),callback:function ($$v) {_vm.$set(_vm.credentials, "username", $$v)},expression:"credentials.username"}})]}}],null,true)}),(!_vm.isMobileView)?_c('v-menu',{ref:"menu_report_number_definition",attrs:{"data-cy":"232","max-width":"400","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-block text-right mb-12",attrs:{"data-cy":"219"}},[_c('span',_vm._g({staticClass:"secondary--text",attrs:{"data-cy":"220"}},on),[_vm._v(_vm._s(_vm.$t("20")))])])]}}],null,true),model:{value:(_vm.showReportNumberHelp),callback:function ($$v) {_vm.showReportNumberHelp=$$v},expression:"showReportNumberHelp"}},[_c('v-card',{staticClass:"pb-6",attrs:{"data-cy":"221","white":""}},[_c('div',{staticClass:"d-block text-right",attrs:{"data-cy":"427"}},[_c('v-btn',{ref:"button_close_help",attrs:{"data-cy":"428","icon":""},on:{"click":function($event){_vm.showReportNumberHelp = false}}},[_c('v-icon',{attrs:{"data-cy":"428"}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"d-block px-md-10 text-center text--secondary",attrs:{"data-cy":"222"}},[_c('span',{attrs:{"data-cy":"223"}},[_vm._v(_vm._s(_vm.$t("21")))]),_c('br',{attrs:{"data-cy":"224"}}),_c('span',{staticClass:"link",attrs:{"data-cy":"225"},domProps:{"innerHTML":_vm._s(_vm.$t('22'))},on:{"click":function($event){return _vm.$router.push('languages')}}})])],1),_c('v-img',{staticClass:"mt-4",attrs:{"data-cy":"430","src":require("@/assets/images/forgot-password.svg"),"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '686',"height":"180","contain":""}})],1)],1):_c('v-bottom-sheet',{ref:"bottom_sheet_report_number_definition",attrs:{"data-cy":"233"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-block text-right mb-12",attrs:{"data-cy":"219"}},[_c('span',_vm._g({staticClass:"secondary--text",attrs:{"data-cy":"220"}},on),[_vm._v(_vm._s(_vm.$t("20")))])])]}}],null,true),model:{value:(_vm.showReportNumberHelp),callback:function ($$v) {_vm.showReportNumberHelp=$$v},expression:"showReportNumberHelp"}},[_c('v-card',{staticClass:"pb-6",attrs:{"data-cy":"221","white":""}},[_c('div',{staticClass:"d-block text-right",attrs:{"data-cy":"427"}},[_c('v-btn',{ref:"button_close_help",attrs:{"data-cy":"428","icon":""},on:{"click":function($event){_vm.showReportNumberHelp = false}}},[_c('v-icon',{attrs:{"data-cy":"428"}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"d-block px-md-10 text-center text--secondary",attrs:{"data-cy":"222"}},[_c('span',{attrs:{"data-cy":"223"}},[_vm._v(_vm._s(_vm.$t("21")))]),_c('br',{attrs:{"data-cy":"224"}}),_c('span',{staticClass:"link",attrs:{"data-cy":"225"},domProps:{"innerHTML":_vm._s(_vm.$t('22'))},on:{"click":function($event){return _vm.$router.push('languages')}}})])],1),_c('v-img',{staticClass:"mt-4",attrs:{"data-cy":"430","src":require("@/assets/images/forgot-password.svg"),"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '686',"height":"180","contain":""}})],1)],1),_c('div',{staticClass:"d-block",attrs:{"data-cy":"234"}},[_vm._v(_vm._s(_vm.$t("17")))]),_c('validation-provider',{attrs:{"data-cy":"235","rules":"required","name":"26"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"input_password",staticClass:"mb-5",attrs:{"data-cy":"236","label":_vm._f("required")(_vm.$t('26')),"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"disabled":!_vm.credentials.username,"error-messages":errors,"color":"secondary","outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"data-cy":"237"}},[_vm._v("mdi-key")])]},proxy:true}],null,true),model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)})]],2)],1),(_vm.$vuetify.breakpoint.smAndDown)?[_c('v-btn',{ref:"button_login",staticClass:"subtitle-1 font-weight-bold",attrs:{"data-cy":"238","color":"primary","loading":_vm.isLoggingIn,"disabled":!(_vm.credentials.username && _vm.credentials.password),"type":"submit","block":"","x-large":""}},[_vm._v(_vm._s(_vm.$t("19")))])]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }