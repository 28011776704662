/**
 * @file It contains scripts for logout view
 */
import { mapActions, mapMutations, mapGetters } from "vuex"
import ThemisPinInput from "@/components/shared/pin-input"
import ThemisPopOver from "@/components/shared/pop-over"

export default {
  name      : "Reports",
  components: { ThemisPinInput, ThemisPopOver },
  data() {
    return {
      credentials: {
        username   : undefined,
        password   : undefined,
        newPassword: undefined
      },
      showPasswordPopOverForMobile: false,
      showNewPassword             : false,
      showPasswordRules           : false,
      passwordValidation          : {
        minimumCharactersPresent         : false,
        minimumNumbersPresent            : false,
        minimumUpperCaseCharactersPresent: false,
        minimumLowerCaseCharactersPresent: false,
        minimumSpecialCharactersPresent  : false
      },
      isPasswordValid : false,
      showNumberCopied: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn          : "auth/isLoggedIn",
      isLoggingIn         : "auth/isLoggingIn",
      isInvalidCredentials: "auth/isInvalidCredentials",
      channels            : "channels/channels",
      report              : "auth/report"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    widthOfPinInputOnMobile() {
      return window.innerWidth / 13.2
    }
  },
  methods: {
    ...mapActions({
      notify               : "shared/notify",
      loginUsingCredentials: "auth/loginUsingCredentials"
    }),
    ...mapMutations({
      setInvalidCredentials: "auth/setInvalidCredentials"
    }),
    getValidationText(rule) {
      return this.$t(this.$LOCALES.PASSWORD_VALIDATION[rule])
    },
    handlePasswordEvents(type) {
      if (type === "focus") {
        this.showPasswordRules            = true
        this.showPasswordPopOverForMobile = true
      }
    },
    async copyReportNumber() {
      await navigator.clipboard.writeText(this.credentials.username)
      this.showNumberCopied = true
    },
    changePasswordForMobile() {
      if (this.showPasswordPopOverForMobile && this.isPasswordValid) {
        this.loginUsingCredentials(this.credentials)
      }
    }
  },
  watch: {
    isLoggedIn: function(newValue) {
      if (newValue) {
        this.notify({
          type: "success",
          text: "38"
        })
        this.$router.push({
          name  : "intake",
          params: {
            locale : this.$route.params.locale,
            channel: this.$route.params.channel
          }
        })
      }
    },
    report: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.credentials.username = newValue.username,
          this.credentials.password = newValue.password
        }
      }
    },
    "credentials.newPassword": function(newValue) {
      this.passwordValidation = {
        minimumCharactersPresent         : newValue?.length >= 12,
        minimumNumbersPresent            : newValue?.match(/\d/),
        minimumUpperCaseCharactersPresent: newValue?.match(/[A-Z]/),
        minimumLowerCaseCharactersPresent: newValue?.match(/[a-z]/),
        minimumSpecialCharactersPresent  : newValue?.match(/[!@#$%ˆ&*]/)
      }
      this.isPasswordValid    = this.passwordValidation.minimumCharactersPresent &&
         this.passwordValidation.minimumNumbersPresent &&
         this.passwordValidation.minimumUpperCaseCharactersPresent &&
         this.passwordValidation.minimumLowerCaseCharactersPresent &&
         this.passwordValidation.minimumSpecialCharactersPresent
    }
  }
}